import { useState, useEffect } from "react";
import PrimaryButton from "../../../components/common/PrimaryButton";
import { ethers } from "ethers";
import ImportNFTAddress from "../../../components/common/ImportNFTAddress";
import * as abis from "../../../abis";
import {
  usePrepareContractWrite,
  useContractWrite,
  useAccount,
  useContractRead,
} from "wagmi";
const Mint = () => {
  const { address } = useAccount();
  const [counter, setCounter] = useState(0);
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  useEffect(() => {
    if (address) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const deployedContract = new ethers.Contract(
        process.env.REACT_APP_NX7NFT_ADDRESS as string,
        abis.buyback as ethers.ContractInterface,
        signer
      );
      const deployedContract2 = new ethers.Contract(
        process.env.REACT_APP_NX7NFT_ADDRESS as string,
        abis.NX7Nft.abi as ethers.ContractInterface,
        signer
      );
      setContract(deployedContract2);
    }
  }, [address]);
  const handleMint = async () => {
    if (contract) {
      try {
        const transaction = await contract.publicMint(counter);
        await transaction.wait();
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };
  const { config: approveConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_USDTCLONE_ADDRESS! as any,
    abi: abis.ERC20 as any,
    functionName: "approve",
    args: [process.env.REACT_APP_NX7NFT_ADDRESS!, 100000000000],
    onSuccess(data) {
      console.log("Settled", { data });
    },
  });
  const { data: mintedNFTs, error: stake3Error } = useContractRead({
    address: process.env.REACT_APP_NX7NFT_ADDRESS! as any,
    abi: abis.NX7Nft.abi as any,
    functionName: "totalSupply",
  });
  if (stake3Error) {
    console.error(
      "An error occurred while reading the contract: ",
      stake3Error
    );
  }
  console.log("minted", mintedNFTs);
  const { write: approveWrite } = useContractWrite(approveConfig);
  const { config: mintConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_NX7NFT_ADDRESS! as any,
    abi: abis.NX7Nft.abi as any,
    functionName: "publicMint",
    args: [counter],
  });
  const { write: mintWrite } = useContractWrite(mintConfig);
  const handleIncrement = () => {
    setCounter((prev) => (prev = counter + 1));
  };
  const handleDecrement = () => {
    if (counter > 0) {
      setCounter((prev) => (prev = counter - 1));
    }
  };
  return (
    <section id="mint-nft" className="container section-margin">
      <h2 className="section-heading text-center">
        Mint NFT
        <img
          className="nftx-x h-[var(--fs-large-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7
      </h2>
      <div className="grid xl:grid-cols-2 gap-20">
        <img id="image_mobile"
          className="rounded-[3%] md:h-[42rem] sm:h-[10rem]"
          src="/assets/images/nfts/NFT-GIF.gif"
          alt="NFT"
        />
        <div className="flex flex-col-reverse md:grid">
          <div>
            <h3 className="secondary-heading">
              THE NFT
              <img
                className="nftx-x h-[var(--fs-large-text)]"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />
              7 TICKET
            </h3>
            <p className="text-justify">
              NFT
              <img
                className="nftx-x h-[var(--fs-small-text)]"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />
              7 is an NFT collection that serves as proof of fractional debt. The
              project aims to invest the cashflow generated from mint sell-outs in
              scalp trading and pay reward to NFT
              <img
                className="nftx-x h-[var(--fs-small-text)]"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />
              7 holders in the form of NX7 tokens, which have a fixed reward
              value. The token has a deflationary mechanism and a built-in volume
              that serves as the cashflow coming from reward payments.
            </p>
            <div className="grid grid-cols-[30px_1fr] items-center gap-x-2 gap-y-5 mb-10">
              <img src="../assets/images/logo/svg-nft-logo.svg" alt="Logo" />
              <h3 className="text-large-text">
                100
                <span className="text-light-purple text-small-text">
                  &nbsp; USDT
                </span>
              </h3>
            </div>
          </div>
          <div>
            <div className="grid auto-rows-fr">
              <div className="flex flex-col items-center md:grid md:grid-cols-[repeat(auto-fill,minmax(150px,1fr))] text-center gap-2">
                <div className="md:w-full w-60">
                  <p className="mb-1">Step 1</p>
                  <PrimaryButton
                    additionalClass="w-full h-16"
                    onClick={() => approveWrite?.()}
                  >
                    Approve
                  </PrimaryButton>
                </div>
                <div className="md:w-full w-60">
                  <p className="mb-1">Step 2</p>
                  <div className="h-16 flex justify-around items-center bg-primary-purple border-2 border-light-purple rounded-[50px] px-4">
                    <button
                      type="button"
                      onClick={handleDecrement}
                      className="leading-none mb-1 text-[30px] w-max"
                    >
                      -
                    </button>
                    <span className="mt-1 text-medium-text leading-none">
                      {counter}
                    </span>
                    <button
                      type="button"
                      onClick={handleIncrement}
                      className="leading-none text-[30px] w-max"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="md:w-full w-60">
                  <p className="mb-1">Step 3</p>
                  <PrimaryButton
                    additionalClass="w-full h-16"
                    onClick={() => mintWrite?.()}
                  >
                    Mint
                  </PrimaryButton>
                </div>
              </div>
            </div>
            <div className="mt-14 mb-10 md:mb-0">
              <ImportNFTAddress />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mint;
