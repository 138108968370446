const HowToMint = () => {
  return (
    <section id="how-to-mint" className="section-margin">
      <div className="container text-center">
        <h2 className="section-heading">How To Mint</h2>
        <div className="flex flex-col items-center mb-14">
          <h4 className="text-medium-text mb-5">Connect your Wallet</h4>
          <img
            className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
            src="assets/images/pages/mint/wallet.jpg"
            alt="Connect Wallet"
          />
        </div>
        <ul className="grid lg:grid-cols-2 place-items-center gap-14">
          <li className="h-full flex flex-col justify-between items-center">
            <div>
              <h6 className="text-light-purple mb-1 text-small-text uppercase">
                Step 1:
              </h6>
              <h4 className="text-medium-text mb-5">Click on Approve.</h4>
            </div>
            <img
              className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
              src="assets/images/pages/mint/step-1.jpg"
              alt="Step 1"
            />
          </li>
          <li className="h-full flex flex-col justify-between items-center">
            <div>
              <h6 className="text-light-purple mb-1 text-small-text uppercase">
                Step 2:
              </h6>
              <h4 className="text-medium-text mb-5">
                Choose the Number of NFT
                <img
                  className="nftx-x h-[var(--fs-medium-text)]"
                  src="../assets/images/logo/nftx-x.png"
                  alt="X"
                />
                7.
              </h4>
            </div>
            <img
              className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
              src="assets/images/pages/mint/step-2.jpg"
              alt="Step 9"
            />
          </li>
          <li className="h-full flex flex-col justify-between items-center">
            <div>
              <h6 className="text-light-purple mb-1 text-small-text uppercase">
                Step 3:
              </h6>
              <h4 className="text-medium-text mb-5">
                Mint your NFT
                <img
                  className="nftx-x h-[var(--fs-medium-text)]"
                  src="../assets/images/logo/nftx-x.png"
                  alt="X"
                />
                7.
              </h4>
            </div>
            <img
              className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
              src="assets/images/pages/mint/step-10.jpg"
              alt="Step 2"
            />
          </li>
          <li className="h-full flex flex-col justify-between items-center">
            <div>
              <h6 className="text-light-purple mb-1 text-small-text uppercase">
                Step 4:
              </h6>
              <h4 className="text-medium-text mb-5">
                Confirm the Request from the wallet.
              </h4>
            </div>
            <img
              className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
              src="assets/images/pages/mint/step-4.jpg"
              alt="Step 3"
            />
          </li>
        </ul>
        <h2 className="section-heading" style={{ marginTop: "80px" }}>
          How To Import
        </h2>
        <ul className="grid lg:grid-cols-2 place-items-center gap-14">
          <li className="h-full flex flex-col justify-between items-center">
            <div>
              <h6 className="text-light-purple mb-1 text-small-text uppercase">
                Step 5:
              </h6>
              <h4 className="text-medium-text mb-5">
                Click on view blockchain explorer.
              </h4>
            </div>
            <img
              className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
              src="assets/images/pages/mint/step-11.jpg"
              alt="Step 4"
            />
          </li>
          <li className="h-full flex flex-col justify-between items-center">
            <div>
              <h6 className="text-light-purple mb-1 text-small-text uppercase">
                Step 6:
              </h6>
              <h4 className="text-medium-text mb-5">
                Copy the NFT
                <img
                  className="nftx-x h-[var(--fs-medium-text)]"
                  src="../assets/images/logo/nftx-x.png"
                  alt="X"
                />
                7 Address & ID.
              </h4>
            </div>
            <img
              className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
              src="assets/images/pages/mint/step-5.jpg"
              alt="Step 5"
            />
          </li>
          <li className="h-full flex flex-col justify-between items-center">
            <div>
              <h6 className="text-light-purple mb-1 text-small-text uppercase">
                Step 7:
              </h6>
              <h4 className="text-medium-text mb-5">
                Import your NFT
                <img
                  className="nftx-x h-[var(--fs-medium-text)]"
                  src="../assets/images/logo/nftx-x.png"
                  alt="X"
                />
                7.
              </h4>
            </div>
            <img
              className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
              src="assets/images/pages/mint/step-6.jpg"
              alt="Step 6"
            />
          </li>
          <li className="h-full flex flex-col justify-between items-center">
            <div>
              <h6 className="text-light-purple mb-1 text-small-text uppercase">
                Step 8:
              </h6>
              <h4 className="text-medium-text mb-5">
                Paste the NFT
                <img
                  className="nftx-x h-[var(--fs-medium-text)]"
                  src="../assets/images/logo/nftx-x.png"
                  alt="X"
                />
                7 Address & ID.
              </h4>
            </div>
            <img
              className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
              src="assets/images/pages/mint/step-7.jpg"
              alt="Step 7"
            />
          </li>
          <li className="h-full flex flex-col justify-between items-center">
            <div>
              <h6 className="text-light-purple mb-1 text-small-text uppercase">
                Step 9:
              </h6>
              <h4 className="text-medium-text mb-5">
                Refresh and your NFT
                <img
                  className="nftx-x h-[var(--fs-medium-text)]"
                  src="../assets/images/logo/nftx-x.png"
                  alt="X"
                />
                7 will appear.
              </h4>
            </div>
            <img
              className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
              src="assets/images/pages/mint/step-8.jpg"
              alt="Step 8"
            />
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HowToMint;
