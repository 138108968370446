import tokenDistribution from "../../../constants/whitepaper/tokenDistribution";

const TokenDistribution = () => {
  let totalPercentage = 0;
  let totalAmount = 0;
  let totalValue = 0;
  return (
    <section id="token-distribution" className="container">
      <h4 className="grid grid-cols-[auto_1fr] gap-2.5 text-medium-text mt-14 mb-4 pt-2.5">
        4.3 Token Distribution
      </h4>
      <div className="md:max-h-none max-h-[600px] md:overflow-hidden overflow-scroll lg:p-10 p-4 mb-14 rounded-3xl bg-tertiary-purple">
        <table>
          <thead>
            <tr>
              <th className="table-heading" colSpan={4}>
                Token Distribution
              </th>
            </tr>
            <tr className="border-t border-b border-white">
              <th className="bg-light-purple">Category</th>
              <th className="bg-light-purple">Percentage</th>
              <th className="bg-light-purple">Amount</th>
              <th className="bg-light-purple">Value</th>
            </tr>
          </thead>
          <tbody>
            {tokenDistribution.map(
              ({ id, category, percentage, amount, value }, index) => {
                totalPercentage += percentage;
                totalAmount += amount;
                totalValue += value;
                return (
                  <tr key={id}>
                    <td
                      className={
                        index + 1 === tokenDistribution.length
                          ? 'after:content-[""] after:table-row after:h-4'
                          : ""
                      }
                    >
                      {category}
                    </td>
                    <td
                      className={
                        index + 1 === tokenDistribution.length
                          ? 'bg-light-purple after:content-[""] after:table-row after:h-4'
                          : "bg-light-purple"
                      }
                    >
                      {percentage}%
                    </td>
                    <td
                      className={
                        index + 1 === tokenDistribution.length
                          ? 'after:content-[""] after:table-row after:h-4'
                          : ""
                      }
                    >
                      {amount}
                    </td>
                    <td
                      className={
                        index + 1 === tokenDistribution.length
                          ? 'after:content-[""] after:table-row after:h-4'
                          : ""
                      }
                    >
                      {value} USDT
                    </td>
                  </tr>
                );
              }
            )}
            <tr className="border-t border-b border-white">
              <td>Total</td>
              <td>{totalPercentage}%</td>
              <td>{totalAmount}</td>
              <td>{totalValue}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default TokenDistribution;
