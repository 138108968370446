export const TabsNFT = () => {
  return (
    <div className="grid lg:auto-rows-fr auto-rows-auto gap-5 my-10">
      <article className="relative box-border lg:w-[60%] max-h-full w-full p-12 rounded-3xl bg-light-purple bg-opacity-50">
        <div className="tab-title">
          <h3 className="mb-6 text-large-text">
            STEP 1: <br />
            Mint your NFT
            <img
              className="nftx-x h-[var(--fs-large-text)]"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7
          </h3>
        </div>
        <p className="text-justify">
          Buy your NFT
          <img
            className="nftx-x h-[var(--fs-paragraph-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 for 100 USDT, and receive a unique NFT that represents your share of
          the fund.
        </p>
      </article>
      <article className="justify-self-center relative box-border lg:w-[60%] w-full max-h-full p-12 rounded-3xl bg-light-purple bg-opacity-50">
        <div className="tab-title">
          <h3 className="mb-6 text-large-text">
            STEP 2: <br />
            Stake your NFT
            <img
              className="nftx-x h-[var(--fs-large-text)]"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7
          </h3>
        </div>
        <p className="text-justify">
          After purchasing your NFT
          <img
            className="nftx-x h-[var(--fs-paragraph-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 collection, stake it to join the rewards program which runs on a
          seven-day cycle.
          <br />
          At the beginning of every seven-day cycle a reward of 1 USDT per
          staked NFT
          <img
            className="nftx-x h-[var(--fs-paragraph-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 worth of NX7 tokens, will be automatically added to your balance and
          ready to be collected and deposited intro your wallet.
        </p>
        <img
          className="hidden lg:block absolute -top-5 -left-2 -translate-x-full w-1/4"
          src="assets/images/nfts/arrow.png"
          alt="Arrow"
        />
      </article>
      <article className="justify-self-end relative box-border lg:w-[60%] w-full max-h-full p-12 rounded-3xl bg-light-purple bg-opacity-50">
        <div className="tab-title">
          <h3 className="mb-6 text-large-text">
            STEP 3: <br />
            Trade your NFT
            <img
              className="nftx-x h-[var(--fs-large-text)]"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7
          </h3>
        </div>
        <p className="text-justify">
          NFT
          <img
            className="nftx-x h-[var(--fs-paragraph-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 owner can sell their NFT on OpenSea for a royalty charge of 10%.
          {/* <br />
          <br />
          Moreover, if the owner wishes to sell their NFT
          <img
            className="nftx-x h-[var(--fs-paragraph-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 back to the collection creators, he needs to apply for it through
          the buyback tab to receive the minting price within 7 days. */}
        </p>
        <img
          className="hidden lg:block absolute -top-5 -left-2 -translate-x-full w-1/4"
          src="assets/images/nfts/arrow.png"
          alt="Arrow"
        />
      </article>
    </div>
  );
};

export default TabsNFT;
