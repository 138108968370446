import Stake from "./components/Stake";
import StakeSteps from "./components/StakeSteps";

const StakeNft = () => {
  return (
    <>
      <Stake />
      <StakeSteps />
    </>
  );
};

export default StakeNft;
