const StakingMechanism = () => {
  return (
    <section id="staking-mechanism" className="container">
      <h4 className="grid grid-cols-[auto_1fr] gap-2.5 text-medium-text mt-14 mb-4 pt-2.5">
        4.5 Staking Mechanism
      </h4>
      <p className="text-justify">
        NFT holders stake their NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7s on the NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 website to receive profit distribution. Only NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 holders who stake their NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7s on the website are eligible to receive distributed reward. Stakers
        receive their reward in profit distribution after a full week of staking
        their NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7s, known as the "Staking Cycle." reward payments are injected as USDT
        into an "reward Distribution Wallet," which is measured by the expected
        volume of reward payment. After completing the "Staking Cycle," one USDT
        is swapped for NX7 tokens for every NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 staked, which is then distributed to the NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 staker. The NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 staker can claim their reward in NX7 tokens at any time after
        completing the "Staking Cycle." In general staking, stakers gain 1 USDT
        worth of tokens every week for every NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 staked. Stakers earn a fixed value of tokens, not a fixed number of
        tokens.
      </p>
    </section>
  );
};

export default StakingMechanism;
