import { useState, useEffect } from "react";
import PrimaryButton from "../../../components/common/PrimaryButton";
import useStake from "../../../hooks/ethCalls/useStake";
import useSetApproval from "../../../hooks/ethCalls/useSetApproval";
import useClaim from "../../../hooks/ethCalls/useClaim";
import useUnstake from "../../../hooks/ethCalls/useUnstake";
import {
  GetApprovalForAll,
  GetTotalStaked,
  GetUserStakedTokens,
} from "../../../hooks/dapp";
import { useEthers } from "@usedapp/core";
import {
  useContractWrite,
  usePrepareContractWrite,
  useContractRead,
} from "wagmi";
import * as abis from "../../../abis";
import { useAccount } from "wagmi";
import EmergencyUnStake from "./EmergencyUnStake";

const Stake = () => {
  const { address } = useAccount();
  const [tokenSelected, setSelectedTokens] = useState<BigInt>();
  const [totalStaked, setstaked] = useState(0);
  const {
    data: tokens,
    error,
    isError,
    isLoading,
  } = useContractRead({
    address: process.env.REACT_APP_NX7NFT_ADDRESS! as any,
    abi: abis.NX7Nft.abi as any,
    functionName: "walletOfOwner",
    args: [address],
  });
  console.log("read data", tokens, error, isLoading);
  const { data: stake1 } = useContractRead({
    address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
    abi: abis.StakingA as any,
    functionName: "getStakedTokens",
    args: [address],
  });
  console.log("read data2", stake1);
  const { data: stake3, error: stake3Error } = useContractRead({
    address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
    abi: abis.StakingA,
    functionName: "getUserDetails",
    args: [address],
  });
  if (stake3Error) {
    console.error(
      "An error occurred while reading the contract: ",
      stake3Error
    );
  }
  console.log("read data3", stake3);
  const { data: stakeUser } = useContractRead({
    address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
    abi: abis.StakingA as any,
    functionName: "stakers",
    args: [address],
  });
  console.log("read data22", stakeUser);
  const { config: stakeConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
    abi: abis.StakingA as any,
    functionName: "stakeBatch",
    args: [tokens],
  });
  const { data: stakeData, write: stakeWrite } = useContractWrite(stakeConfig);
  const { config: stake1Config } = usePrepareContractWrite({
    address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
    abi: abis.StakingA as any,
    functionName: "stake",
    args: [tokenSelected],
  });
  let stake2 = stake1?.length;
  const { data: stake1Data, write: stake1Write } =
    useContractWrite(stake1Config);
  const { config: approveConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_NX7NFT_ADDRESS! as any,
    abi: abis.NX7Nft.abi as any,
    functionName: "setApprovalForAll",
    args: [process.env.REACT_APP_NX7STAKING_ADDRESS!, true],
  });
  //add chosen id
  const { write: approveWrite } = useContractWrite(approveConfig);
  const { config: claimConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
    abi: abis.StakingA as any,
    functionName: "claimReward",
    args: [address],
  });
  const { write: claimWrite } = useContractWrite(claimConfig);
  const [tokenIds, setTokenIds] = useState<number[]>([]);
  const [stakedTokenIds, setStakedTokenIds] = useState<number[]>([]);
  //Georgio Added from here





  const [collecting, setCollecting] = useState(false);
  const [totalPendingRewards, setTotalPendingRewards] = useState(0);
  const pendingRewardsPerNFT = 1; // $1
  const rewardInterval = 5; // minutes
  const [lastClaimTimestamp, setLastClaimTimestamp] = useState(Date.now());
  const [countdown, setCountdown] = useState(rewardInterval * 60); // Initial countdown value in seconds
  const calculatePendingRewards = (
    stakedNFTCount: number,
    elapsedTime: number
  ) => {
    const elapsedMinutes = Math.floor(elapsedTime / (1000 * 60)); // Convert elapsed time to minutes
    const pendingRewards =
      (stakedNFTCount * elapsedMinutes * pendingRewardsPerNFT) / rewardInterval;
    return pendingRewards;
  };
  //Georgio Added to here
  // Show user his tokenIds in a dropdown and let him choose one to stake
  // Remove the line below and replace the tokenId with the one chosen by the user
  const tokenId = tokenIds[0];
  const { account, activateBrowserWallet } = useEthers();
  const { stake, stakeState } = useStake();
  const { setApprovalForAll, setApprovalForAllState } = useSetApproval();
  const { claim, claimState } = useClaim();
  const { unstake, unstakeState } = useUnstake();
  const approved = GetApprovalForAll(account as string);
  const stakedTokens = GetUserStakedTokens(account as string);
  const connect = () => {
    activateBrowserWallet();
    //handleChangeNetwork();
  };


  const claimFlow = async () => {
    claimWrite?.();
  };

  // const countdownTimer = () => {
  //   if (countdown === 0) {
  //     // Reset countdown to rewardInterval * 60 seconds when it reaches 0
  //     setCountdown(rewardInterval * 60);
  //     setLastClaimTimestamp(Date.now()); // Reset the last claim timestamp
  //   }  else if (countdown > 0) {
  //     // Decrement countdown by 1 second if it's greater than 0
  //     setCountdown((prevCountdown) => prevCountdown - 1);
  //   }
  // };
  const [rewardsReady, setRewardsReady] = useState(false); // Add this state variable

  const countdownTimer = () => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastClaimTimestamp;

    if (elapsedTime >= rewardInterval * 60 * 1000) {
      // If more than reward interval has passed, reset countdown to 7 days
      setCountdown(604800);
      setLastClaimTimestamp(currentTime);
      setRewardsReady(true); // Set state to indicate rewards are ready
    } else {
      // Calculate remaining time based on elapsed time and reward interval
      let remainingTimeInSeconds = Math.ceil((rewardInterval * 60) - (elapsedTime / 1000));

      // If the countdown goes negative, reset it to 0 and indicate rewards are ready
      if (remainingTimeInSeconds <= 0) {
        remainingTimeInSeconds = 0;
        setRewardsReady(true);
      } else {
        setRewardsReady(false); // Set state to indicate rewards are not ready
      }

      setCountdown(remainingTimeInSeconds);
    }
  };




  // Use useEffect to start the countdown when the component mounts
  useEffect(() => {
    if (countdown > 0) {
      const interval = setInterval(countdownTimer, 1000); // Update countdown every second
      return () => clearInterval(interval); // Clean up the interval when the component unmounts
    }

  }, [lastClaimTimestamp]);

  const unstakeFlow = async (stakedItemId: number) => {
    if (account) {
      unstake(stakedItemId);
    } else {
      connect();
    }
  };
  const totalRewards = () => {
    let sum = 0;
    (stake3 as any)[2].map((reward: any) => {
      sum += Number(reward) / Math.pow(10, 6);
    });
    return sum;
  };
  return (
    <section id="stake-nft" className="container section-margin">
      <h2 className="section-heading text-center">
        Stake NFT
        <img
          className="nftx-x h-[var(--fs-large-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7
      </h2>
      <div className="flex justify-center gap-2.5">
        <div className="flex flex-col items-center mb-5">
          Step 1
          <PrimaryButton
            additionalClass="px-6 py-2"
            onClick={() => approveWrite?.()}
          >
            Approve
          </PrimaryButton>
        </div>
        <div className="flex flex-col items-center mb-5">
          Step 2
          <PrimaryButton
            additionalClass="px-6 py-2"
            onClick={() => stakeWrite?.()}
          >
            Stake All
          </PrimaryButton>
        </div>
      </div>
      <div className="flex flex-col md:grid md:grid-cols-[1fr_3fr] gap-10">
        <div className="pt-7 px-7 pb-5 border border-light-purple rounded-3xl">
          <h3 className="text-light-purple text-medium-text mb-2 uppercase">
            Staked NFT
            <img
              className="nftx-x h-[var(--fs-medium-text)]"
              src="../assets/images/logo/nftx-x.png"
              alt="X"
            />
            7
          </h3>
          <div className="flex flex-wrap justify-center gap-5 pt-5 pb-3">
            <p className="text-large-text">{stake2}</p>
          </div>
        </div>
        <div className="pt-7 px-7 pb-5 border border-light-purple rounded-3xl">
          <h3 className="text-light-purple text-medium-text mb-2 uppercase">
            NX7 Earned
          </h3>
          <div className="flex flex-wrap justify-between gap-1 p-4 mt-5 border border-light-purple rounded-2xl text-small-text">
            <p>Total due rewards</p>
            <div>
              <div className="amount-container">
                <div>
                  {(stake3 as any) && (
                    <>
                      <span className="uppercase text-medium-text">
                        {totalPendingRewards ? 0 : totalRewards()}
                      </span>
                      <span className="uppercase">USDT worth of NX7</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <PrimaryButton
              additionalClass="float-right text-small-text px-6 py-2 whitespace-nowrap"
              onClick={claimFlow}
            >
              {collecting ? "Collecting..." : "Collect All"}
            </PrimaryButton>
          </div>
        </div>
        <div className="px-1 pb-5 border text-tiny-text text-center border-light-purple rounded-3xl col-span-2">
          <div className="mt-5 grid">
            <div className="grid grid-cols-[3fr_6fr_2fr_2fr] place-items-center gap-0.5 mb-2">
              <span className="uppercase text-light-purple">
                Staked NFT
                <img
                  className="nftx-x h-[var(--fs-tiny-text)]"
                  src="../assets/images/logo/nftx-x.png"
                  alt="X"
                />
                7 ref
              </span>
              <span className="uppercase text-light-purple">
                Time till next reward
              </span>
              <span className="uppercase text-light-purple">Due reward</span>
              <span className="uppercase text-light-purple">Actions</span>
            </div>
            {stake3 &&
              (stake3 as any)[0].map((stakedItem: any, iterator: any) => {
                let i = Number(stakedItem) - 1;
                const pendingRewards = calculatePendingRewards(
                  1,
                  Date.now() - lastClaimTimestamp
                ); // Calculate pending rewards for each staked NFT

                console.log(`fired  ${i}`, (stake3 as any)[1][iterator]);
                const remainingTimeInSeconds =
                  604800 -
                  (Date.now() - Number((stake3 as any)[1][iterator]) * 1000) /
                  1000;
                const days = Math.floor(remainingTimeInSeconds / 86400);
                const hours = Math.floor(
                  Math.floor((remainingTimeInSeconds % 86400) / 60) / 60
                );
                const minutes = Math.floor((remainingTimeInSeconds / 60) % 60);
                const reward =
                  Number((stake3 as any)[2][iterator]) / Math.pow(10, 6);

                return (
                  <div
                    key={`staked_${Number(stakedItem)}`}
                    className="grid grid-cols-[3fr_6fr_2fr_2fr] place-items-center gap-0.5 border-t-2 border-light-purple pb-2.5"
                  >
                    <p className="pl-1 box-content">
                      NFT #{Number(stakedItem)}
                    </p>
                    {remainingTimeInSeconds <= 0 ? (
                      <p className="text-white">Rewards Ready to Collect</p>
                    ) : (
                      <p id="frontend-timer" className="text-white">
                        {days} days {hours} hours {minutes} minutes
                      </p>
                    )}
                    <p className="text-white">{reward}</p>
                    <div>
                      <EmergencyUnStake id={Number(stakedItem)} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stake;
