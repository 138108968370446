const TokenUtilitzation = () => {
  return (
    <section id="token-utilization" className="container">
      <h4 className="grid grid-cols-[auto_1fr] gap-2.5 text-medium-text mt-14 mb-4 pt-2.5">
        4.2 Token Utilization
      </h4>
      <p className="text-justify">
        The NX7 token is designed for usage as a means of payment and is a
        transactional token with a deflationary mechanism. The token is used as
        an reward payment for NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 stakers and can also be used as collateral to leverage managed funds.
        The token appreciates through the injection of liquidity from buying
        turnover from reward payments. The minimum price reach is calculated by
        the token economics team. The liquidity pool value for NX7 is 20,000
        USDT and 200,000 NX7, with an initial token price of 0.1 USDTor 0.1
        USDT/NX7. The total supply is 5,000,000 with a burning fee of 1% on
        selling volume. The vesting schedules range from 0 to 60 months, with
        the ticker as NX7 and decimals set to 5. The selling decimals are set to
        3, and the capitalization depends on the founder's selloff with an
        initial market capitalization of 223,854.67 USDTand initial full diluted
        market capitalization of 500,000. USDT The token is built on the Polygon
        network and will be traded on Uniswap (V2).
      </p>
    </section>
  );
};

export default TokenUtilitzation;
