import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/common/PrimaryButton";

const OurStory = () => {
  const navigate = useNavigate();

  return (
    <section
      id="about-our-story"
      className="container section-margin xl:grid flex flex-col-reverse xl:grid-cols-2 gap-12"
    >
      <article>
        <h2 className="secondary-heading">
          The Story of NFT
          <img
            className="nftx-x h-[var(--fs-large-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7
        </h2>
        <p className="text-justify leading-8">
          NFT
          <img
            className="nftx-x h-[var(--fs-paragraph-text)]"
            src="../assets/images/logo/nftx-x.png"
          />
          7 is an NFT collection that serves as proof of fractional debt. The
          project aims to invest the cashflow generated from mint sell-outs in
          scalp trading and pay reward to NFT
          <img
            className="nftx-x h-[var(--fs-paragraph-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 holders in the form of NX7 tokens, which have a fixed reward value.
          The token has a deflationary mechanism and a built-in volume that
          serves as the cashflow coming from reward payments.
        </p>
      </article>
      <div id ="gif_mobile "className="flex flex-col justify-center items-center gap-5">
        <img
          className="rounded-[3%] w-3/4 aspect-square"
          src="assets/images/nfts/NFT-GIF.gif"
          alt="NFT GIF"
        />
        <PrimaryButton additionalClass="px-10 py-2.5" onClick={() => navigate("/mint")}>Mint</PrimaryButton>
      </div>
    </section>
  );
};

export default OurStory;
