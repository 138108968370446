import PrimaryButton from "../../../components/common/PrimaryButton";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import * as abis from "../../../abis";

const EmergencyUnStake = ({ id }: { id: number }) => {
  const { config: unstakeNoRewards } = usePrepareContractWrite({
    address: process.env.REACT_APP_NX7STAKING_ADDRESS! as any,
    abi: abis.StakingA as any,
    functionName: "emergencyUnstake",
    args: [id],
  });
  const { write: unstake } = useContractWrite(unstakeNoRewards);
  return (
    <PrimaryButton
      onClick={() => unstake?.()}
      additionalClass="text-small-text px-3 py-1"
    >
      Unstake
    </PrimaryButton>
  );
};

export default EmergencyUnStake;
