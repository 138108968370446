const CreatedBy = () => {
  return (
    <section
      id="team-created"
      className="section-margin flex flex-col items-center"
    >
      <h2 className="section-heading text-center">Created By</h2>
      <img
        className="max-w-[320px]"
        src="/assets/images/logo/digital-x.png"
        alt="Digital-X"
      />
    </section>
  );
};

export default CreatedBy;
