import { FC } from 'react';
import './App.css';
// import {useAppSelector} from "./store/redux";
import AppRouter from './routes/AppRouter';
import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { polygon } from 'wagmi/chains';

const { publicClient, webSocketPublicClient } = configureChains(
    [polygon],
    [
        jsonRpcProvider({
            rpc: (chain) => ({
                http: `https://polygon-rpc.com/`,
            }),
        }),
    ]
);

const config = createConfig({
    publicClient,
    webSocketPublicClient,
});

export const App: FC = () => {
    // const token  = useAppSelector(state => state.authReducer.token);
    return (
        <WagmiConfig config={config}>
            <AppRouter />
        </WagmiConfig>
    );
};
