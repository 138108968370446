import { useNavigate } from "react-router-dom";
import SocialIcon from "../common/SocialIcon";
import defaultNavigationData from "../../constants/navigations";
import defaultSocialLinks from "../../constants/socials";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <section id="site-footer" className="bg-dark-purple">
      <div className="container flex md:flex-row flex-col justify-between gap-16 pt-20 pb-10">
        <div className="flex flex-col justify-between gap-10">
          <img
            className="max-w-[150px]"
            src="/assets/images/logo/svg-logo-white.svg"
            alt="NFTX7 Logo"
          />
          <p>
            © 2023 NFT
            <img
              className="nftx-x h-[var(--fs-small-text)]"
              src="assets/images/logo/nftx-x.png"
              alt="X"
            />
            7. All right reserved.
          </p>
        </div>
        <nav className="footer-nav">
          <ul className="flex flex-wrap gap-x-4 gap-y-2">
            {defaultNavigationData.map((menuItem) => {
              return (
                <li
                  className={`text-paragraph-text uppercase font-bold transition-transform duration-700 cursor-pointer hover:-translate-x-1 hover:-translate-y-1 ${
                    window.location.pathname == menuItem.path &&
                    "text-light-purple"
                  }`}
                  key={menuItem.id}
                  onClick={() => {
                    menuItem.external_link
                      ? (window.location.href = menuItem.path)
                      : navigate(menuItem.path);
                  }}
                >
                  {menuItem.title}
                </li>
              );
            })}
            <li className="text-paragraph-text uppercase font-bold">
              <div className="footer-community">
                <p>Community</p>
                <div className="flex gap-5 mt-6">
                  {defaultSocialLinks.map((icon) => {
                    return (
                      <div
                        key={`footer_socials_${icon.id}`}
                        className="w-14 transition-transform hover:-translate-x-1 hover:-translate-y-1"
                      >
                        <SocialIcon title={icon.title} url={icon.url} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default Footer;
