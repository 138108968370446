export const defaultNavigationData = [
  {
    id: "navigation_about",
    title: "About",
    path: "/about",
    selected: false,
  },
  {
    id: "navigation_mint",
    title: "Mint",
    path: "/mint",
    selected: false,
  },
  {
    id: "navigation_stake",
    title: "Stake",
    path: "/stake",
    selected: false,
  },
  // {
  //   id: "navigation_buyback",
  //   title: "Buyback",
  //   path: "/buyback",
  //   selected: false,
  // },
  {
    id: "navigation_whitepaper",
    title: "Whitepaper",
    path: "/whitepaper",
    selected: false,
  },
  {
    id: "navigation_team",
    title: "Team",
    path: "/team",
    selected: false,
  },
  {
    id: "navigation_nx7",
    title: "NX7",
    external_link: true,
    path: "https://nx7.io",
    selected: false,
  },
];

export default defaultNavigationData;
