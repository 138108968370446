import { getChainById, ChainId } from "@usedapp/core";
import { ethers } from "ethers";

const DappConfig = {
    readOnlyChainId: ChainId.Polygon,
    readOnlyUrls: {
        [ChainId.Polygon]: ethers.getDefaultProvider(process.env.REACT_APP_RPC_URL),
    },
    networks: [getChainById(ChainId.Polygon)],
    autoConnect: false,
};

export default DappConfig;
