import { useContractRead } from "wagmi";
import * as abis from "../../../abis";
const OurCollection = () => {
  const { data: mintedNFTs, error: stake3Error } = useContractRead({
    address: process.env.REACT_APP_NX7NFT_ADDRESS! as any,
    abi: abis.NX7Nft.abi as any,
    functionName: "totalSupply",
  });
  return (
    <section
      id="mint-banner"
      className="container flex flex-col justify-center items-center text-center uppercase section-margin"
    >
      <h2 className="md:text-5xl text-3xl mb-5">
        NFT
        <img
          className="nftx-x md:h-[3rem] h-[30px]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 Collection
      </h2>
      <div className="flex md:flex-row flex-col items-center gap-2">
        <h4>minted</h4>
        <h1 className="md:text-8xl text-6xl text-light-purple">
          {Number(mintedNFTs! as any)}/10,000
        </h1>
      </div>
    </section>
  );
};

export default OurCollection;
