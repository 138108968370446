import stakeSteps from "../../../constants/stakeSteps";

const StakeSteps = () => {
  return (
    <section id="how-to-stake" className="section-margin">
      <div className="container">
        <h2 className="section-heading text-center">How To Stake</h2>
        <ul className="grid md:grid-cols-2 gap-14">
          {stakeSteps.map((title, index) => {
            return (
              <li
                key={`stake_step_${index}`}
                className="h-full flex flex-col justify-between"
              >
                <div>
                  <h6 className="uppercase text-center mb-1 text-light-purple text-small-text">
                    Step {index + 1}
                  </h6>
                  <h4 className="text-center mb-5 text-medium-text">{title}</h4>
                </div>
                <img
                  className="border border-light-purple object-contain max-h-[600px] w-max rounded-[37.5px]"
                  src={`assets/images/pages/stake/step-${index + 1}.jpg`}
                  alt={`Step ${index + 1}`}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default StakeSteps;
