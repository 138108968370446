import "./style.css";
import Technology from "./components/Technology";
import FAQS from "./components/FAQS";
import Introduction from "./components/Introduction";
import Tabs from "./components/Tabs";
import TokenomicsTitle from "./components/TokenomicsTitle";
import MonetaryPolicy from "./components/MonetaryPolicy";
import TokenUtilitzation from "./components/TokenUtilitzation";
import Anchors from "./components/Anchors";
import TokenDistribution from "./components/TokenDistribution";
import VestingSchedule from "./components/VestingSchedule";
import StakingMechanism from "./components/StakingMechanism";
import Conclusion from "./components/Conclusion";

const Whitepaper = () => {
  return (
    <>
      <Anchors />
      <Introduction />
      <Tabs />
      <Technology />
      <TokenomicsTitle />
      <MonetaryPolicy />
      <TokenUtilitzation />
      <TokenDistribution />
      <VestingSchedule />
      <StakingMechanism />
      <Conclusion />
      <FAQS />
    </>
  );
};

export default Whitepaper;
