import OurStory from "./components/OurStory";
import JoinBanner from "./components/JoinBanner";
import TabsNFT from "../../components/common/TabNFT";

const About = () => {
  return (
    <>
      <JoinBanner />
      <OurStory />
      <section id="about-choose" className="container section-margin">
        <h2 className="section-heading text-center">How it works</h2>
        <TabsNFT />
      </section>
    </>
  );
};

export default About;
