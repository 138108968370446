export const vestingSchedules = {
  team_unlocking: 12500.0,
  team_share: 0.25,
  ecosystem_unlocking: 26041.67,
  ecosystem_share: 0.52,
  advisory_unlocking: 50000,
  advisory_share: 1,
  treasury_unlocking: 150000,
  treasury_share: 3,
};

export default vestingSchedules;
