import TabsNFT from "../../../components/common/TabNFT";

const Tabs = () => {
  return (
    <section
      id="whitepaper-nft"
      className="container numbered-section pt-2.5 text-section-text mt-20"
    >
      <h3 className="section-heading text-white uppercase text-left">02 NFT</h3>
      <TabsNFT />
    </section>
  );
};

export default Tabs;
