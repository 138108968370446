const MonetaryPolicy = () => {
  return (
    <section id="monetary-policy" className="container">
      <h4 className="grid grid-cols-[auto_1fr] gap-2.5 text-medium-text mt-14 mb-4">
        4.1 Monetary Policy
      </h4>
      <p className="text-justify">
        NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 is a cryptocurrency project that uses NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7s to crowd-fund a trading portfolio. The project is built around the
        expected volume from its issuers, taking into consideration factors like
        volatility, valuations, and economic behavior. The NX7 token is designed
        to be deflationary, with 1% of tokens burned upon selling and a 1%
        marketing tax to fuel the NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 project. The token's initial liquidity pool is deployed on Uniswap
        (V2) with an initial liquidity of 20,000 USDT and 200,000 NX7, which
        determines the initial price of the token at 0.1 USDT or 0.1 USDT/NX7.
        As more transactions occur and more holders commit, the token becomes
        less volatile and maintains steady price growth due to its deflationary
        model and buying volumes from the team to distribute reward. The team
        plans to add two more times additional liquidity to the pool to have
        strong liquidity and build financial credibility with the community. NX7
        will not face devaluation since it starts at 0.1 USDT per token and its
        supply is being reduced. The token economics team of NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 will calculate monthly the minimum value the token would reach if all
        the supply is sold and list it for the community. The NX7 vesting
        schedule of locked tokens is designed based on the expected volume, and
        the token has a high circulating supply initially since it is a
        transactional token.
      </p>
    </section>
  );
};

export default MonetaryPolicy;
