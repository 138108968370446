import faqs from "../../../constants/whitepaper/faqs";
import { useState } from "react";

const FAQS = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <section id="whitepaper-faqs" className="container">
      <h3 className="section-heading text-white uppercase text-left mt-10">
        06 FAQS
      </h3>
      <div>
        {faqs.map(({ id, title, text }) => {
          return (
            <article
              key={`faq_tab_${id}`}
              className={`my-16 faq-tab-${id} ${
                activeTab === id ? "active" : ""
              }`}
            >
              <h6
                className={`text-medium-text cursor-pointer flex justify-between border-b-2 border-white mb-6 after:content-['^'] after:transition-transform after:duration-500 ${
                  activeTab === id ? "after:rotate-180" : "after:rotate-0"
                }`}
                onClick={() => setActiveTab(id)}
              >
                {title}
              </h6>
              <p
                className={`text-justify ${
                  activeTab === id ? "block" : "hidden"
                }`}
              >
                {text}
              </p>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default FAQS;
