const PrimaryButton = ({
  children,
  additionalClass,
  onClick,
}: {
  children: React.ReactNode;
  additionalClass?: string;
  onClick: () => void;
}) => {
  return (
    <button
      type="button"
      className={`uppercase font-bold text-paragraph-text text-white rounded-[100px] text-center border-2 border-primary-purple bg-tertiary-purple transition-coolors duration-700 hover:border-transparent hover:bg-primary-purple ${
        additionalClass ? additionalClass : "w-max"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
