import teamMembers from "../../../constants/teamMembers";

const MeetOurTeam = () => {
  return (
    <section id="team-meet" className="container text-center section-margin">
      <h2 className="section-heading text-center">Meet our team</h2>
      <div className="flex flex-row-reverse flex-wrap-reverse justify-center gap-20">
        {teamMembers.map((team) => {
          return (
            <article
              key={`team_${team.id}`}
              className="box-content p-12 rounded-3xl border-2 border-light-purple max-w-[250px] transition-colors duration-500 hover:border-secondary-purple hover:bg-primary-purple"
            >
              <img src={team.image} alt={team.title} />
              <h3 className="uppercase font-bold text-medium-text mt-5 mb-1">
                {team.title}
              </h3>
              <p className="text-justify text-small-text text-light-purple">
                {team.description}
              </p>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default MeetOurTeam;
