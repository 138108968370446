const Anchors = () => {
  return (
    <section
      id="whitepaper-anchors"
      className="bg-[url('/public/assets/images/pages/whitepaper/background-anchors.png')] bg-cover pb-10"
    >
      <div className="container grid grid-cols-[auto_1fr] auto-cols-fr gap-x-2.5 gap-y-6 py-10 text-medium-text">
        <span>01</span>
        <a href="#whitepaper-introduction" className="w-max">
          <h3 className="uppercase">Introduction</h3>
        </a>
        <span>02</span>
        <a href="#whitepaper-nft" className="w-max">
          <h3 className="uppercase">NFT</h3>
        </a>
        <span>03</span>
        <a href="#whitepaper-technology" className="w-max">
          <h3 className="uppercase">The technology behind NX7</h3>
        </a>
        <span>04</span>
        <div>
          <a href="#whitepaper-tokenomics" className="w-max">
            <h3 className="uppercase">Tokenomics</h3>
          </a>
          <div className="grid grid-cols-[auto_1fr] auto-rows-fr gap-2.5 py-2.5">
            <span>4.1</span>
            <a href="#monetary-policy">
              <h4>Monetary Policy</h4>
            </a>
            <span>4.2</span>
            <a href="#token-utilization">
              <h4>Token Utilization</h4>
            </a>
            <span>4.3</span>
            <a href="#token-distribution">
              <h4>Token Distribution</h4>
            </a>
            <span>4.4</span>
            <a href="#vesting-schedules">
              <h4>Vesting Schedules</h4>
            </a>
            <span>4.5</span>
            <a href="#staking-mechanism">
              <h4>Staking Mechanism</h4>
            </a>
          </div>
        </div>
        <span>05</span>
        <a href="#whitepaper-conclusion" className="w-max">
          <h3 className="uppercase">Conclusion</h3>
        </a>
        <span>06</span>
        <a href="#whitepaper-faqs" className="w-max">
          <h3 className="uppercase">FAQS</h3>
        </a>
      </div>
      <div className="container">
        <a
          className="py-2.5 px-8 uppercase font-bold text-paragraph-text text-white rounded-[100px] text-center border-2 border-primary-purple bg-tertiary-purple transition-coolors duration-700 hover:border-transparent hover:bg-primary-purple"
          download={"Whitepaper-nftx7.pdf"}
          href="/assets/Whitepaper-nftx7.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Whitepaper PDF
        </a>
      </div>
    </section>
  );
};

export default Anchors;
