export const defaultSocialLinks = [
  {
    id: "social_discord",
    title: "Discord",
    url: "https://discord.gg/feyEK2z7n7",
  },
  {
    id: "social_twitter",
    title: "Twitter",
    url: "https://twitter.com/NFTX7Official",
  },
  {
    id: "social_instagram",
    title: "Instagram",
    url: "https://www.instagram.com/nftx7official",
  },
  {
    id: "social_telegram",
    title: "Telegram",
    url: "https://t.me/NFTX7NX7",
  },
];

export default defaultSocialLinks