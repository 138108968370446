import HowToMint from "./components/HowToMint";
import Mint from "./components/Mint";
import OurCollection from "./components/OurCollection";

const MyNft = () => {
  return (
    <>
      <OurCollection />
      <Mint />
      <HowToMint />
    </>
  );
};

export default MyNft;
