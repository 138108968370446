const Introduction = () => {
  return (
    <section
      id="whitepaper-introduction"
      className="container pt-2.5 text-section-text mt-14"
    >
      <h3 className="section-heading text-white uppercase text-left">
        01 Introduction
      </h3>
      <p className="text-justify">
        NFT
        <img
          className="nftx-x h-[var(--fs-small-text)] -mb-1.5"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 is an NFT collection that serves as proof of fractional debt. The
        project aims to invest the cashflow generated from mint sell-outs in
        scalp trading and pay reward to NFT holders in the form of NX7 tokens,
        which have a fixed reward value. The token has a deflationary mechanism
        and a built-in volume that serves as the cashflow coming from reward
        payments. NX7 is a transactional token and a peer-to-peer means of
        exchange used to transfer value within the network. Its main volume
        comes from issuers who transfer the financial reward value to NFT
        <img
          className="nftx-x h-[var(--fs-small-text)] -mb-1.5"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 holders. NX7 is a deflationary ecosystem that maintains its value by
        incentivizing and fueling transactions. Its primary utility is to pay
        NFT
        <img
          className="nftx-x h-[var(--fs-small-text)] -mb-1.5"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 holders a fixed-value reward, and token holders can utilize it to
        transfer wealth and value among themselves. The ecosystem has a built-in
        volume that fuels transactions and a deflationary model that can
        mathematically increase its value due to the burning effect and the
        automated market maker model, which defines the balancing method for
        pricing tokens. The process involves NFT
        <img
          className="nftx-x h-[var(--fs-small-text)] -mb-1.5"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 holders staking their NFT
        <img
          className="nftx-x h-[var(--fs-small-text)] -mb-1.5"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7s to earn reward and generate passive income. Every NFT
        <img
          className="nftx-x h-[var(--fs-small-text)] -mb-1.5"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 staked gains 1 USDT worth of NX7 in each staking cycle, which lasts
        for one week (7 days). The NFT does not gain a fixed number of tokens
        but a fixed value of reward. USDT is set aside in a specific wallet for
        reward distribution. After completing a staking cycle, the smart
        contract executes a buying order of 1 USDT for each NFT
        <img
          className="nftx-x h-[var(--fs-small-text)] -mb-1.5"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 and distributes it immediately to be claimed by the NFT
        <img
          className="nftx-x h-[var(--fs-small-text)] -mb-1.5"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 staker. Buying orders occur every time an NFT
        <img
          className="nftx-x h-[var(--fs-small-text)] -mb-1.5"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 holder completes the staking cycle.
      </p>
    </section>
  );
};

export default Introduction;
