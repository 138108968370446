import vestingSchedules from "../../../constants/whitepaper/vestingSchedules";

const VestingSchedule = () => {
  let teamArray = [];
  for (let i = 0; i < 60; i++) {
    teamArray[i] = {
      month: i,
      unlocking: vestingSchedules.team_unlocking,
      circulating:
        vestingSchedules.team_unlocking + vestingSchedules.team_unlocking * i,
      share: vestingSchedules.team_share + vestingSchedules.team_share * i,
    };
  }
  let ecosystemArray = [];
  for (let i = 0; i < 48; i++) {
    ecosystemArray[i] = {
      month: i,
      unlocking: vestingSchedules.ecosystem_unlocking,
      circulating:
        vestingSchedules.ecosystem_unlocking +
        vestingSchedules.ecosystem_unlocking * i,
      share:
        vestingSchedules.ecosystem_share + vestingSchedules.ecosystem_share * i,
    };
  }
  let advisoryArray = [];
  for (let i = 0; i < 5; i++) {
    advisoryArray[i] = {
      month: i,
      unlocking: vestingSchedules.advisory_unlocking,
      circulating:
        vestingSchedules.advisory_unlocking +
        vestingSchedules.advisory_unlocking * i,
      share:
        vestingSchedules.advisory_share + vestingSchedules.advisory_share * i,
    };
  }
  let treasuryArray = [];
  for (let i = 0; i < 5; i++) {
    treasuryArray[i] = {
      month: i,
      unlocking: vestingSchedules.treasury_unlocking,
      circulating:
        vestingSchedules.treasury_unlocking +
        vestingSchedules.treasury_unlocking * i,
      share:
        vestingSchedules.treasury_share + vestingSchedules.treasury_share * i,
    };
  }
  return (
    <section id="vesting-schedules" className="container">
      <h4 className="number-sub-section grid grid-cols-[auto_1fr] gap-2.5 text-medium-text mt-12 mb-2">
        4.4 Vesting Schedules
      </h4>
      <div className="my-8 rounded-3xl bg-tertiary-purple md:overflow-hidden overflow-scroll max-h-[600px]">
        <table>
          <thead>
            <tr>
              <th className="table-heading" colSpan={4}>
                Team Tokens
              </th>
            </tr>
            <tr>
              <th className="bg-light-purple">Month</th>
              <th className="bg-light-purple">Unlocking schedule</th>
              <th className="bg-light-purple">
                Addition to circulating supply
              </th>
              <th className="bg-light-purple">Share of total supply</th>
            </tr>
          </thead>
          <tbody>
            {teamArray.map((item) => {
              return (
                <tr key={`team_${item.month}`}>
                  <td>{item.month + 1}</td>
                  <td>{item.unlocking}</td>
                  <td>{Math.round(item.circulating * 100) / 100}</td>
                  <td>{Math.round(item.share * 100) / 100}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="my-8 rounded-3xl bg-tertiary-purple md:overflow-hidden overflow-scroll max-h-[600px]">
        <table>
          <thead>
            <tr>
              <th className="table-heading" colSpan={4}>
                Ecosystem/Expense
              </th>
            </tr>
            <tr>
              <th className="bg-light-purple">Month</th>
              <th className="bg-light-purple">Unlocking schedule</th>
              <th className="bg-light-purple">
                Addition to circulating supply
              </th>
              <th className="bg-light-purple">Share of total supply</th>
            </tr>
          </thead>
          <tbody>
            {ecosystemArray.map((item) => {
              return (
                <tr key={`ecosystem_${item.month}`}>
                  <td>{item.month + 1}</td>
                  <td>{item.unlocking}</td>
                  <td>{Math.round(item.circulating * 100) / 100}</td>
                  <td>{Math.round(item.share * 100) / 100}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="my-8 rounded-3xl bg-tertiary-purple md:overflow-hidden overflow-scroll max-h-[600px]">
        <table>
          <thead>
            <tr>
              <th className="table-heading" colSpan={4}>
                Advisory
              </th>
            </tr>
            <tr>
              <th className="bg-light-purple">Year</th>
              <th className="bg-light-purple">Unlocking schedule</th>
              <th className="bg-light-purple">
                Addition to circulating supply
              </th>
              <th className="bg-light-purple">Share of total supply</th>
            </tr>
          </thead>
          <tbody>
            {advisoryArray.map((item) => {
              return (
                <tr key={`advisory_${item.month}`}>
                  <td>{item.month + 1}</td>
                  <td>{item.unlocking}</td>
                  <td>{Math.round(item.circulating * 100) / 100}</td>
                  <td>{Math.round(item.share * 100) / 100}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="my-8 rounded-3xl bg-tertiary-purple md:overflow-hidden overflow-scroll max-h-[600px]">
        <table>
          <thead>
            <tr>
              <th className="table-heading" colSpan={4}>
                Treasury and Reserve
              </th>
            </tr>
            <tr>
              <th className="bg-light-purple">Year</th>
              <th className="bg-light-purple">Unlocking schedule</th>
              <th className="bg-light-purple">
                Addition to circulating supply
              </th>
              <th className="bg-light-purple">Share of total supply</th>
            </tr>
          </thead>
          <tbody>
            {treasuryArray.map((item) => {
              return (
                <tr key={`treasury_${item.month}`}>
                  <td>{item.month + 1}</td>
                  <td>{item.unlocking}</td>
                  <td>{Math.round(item.circulating * 100) / 100}</td>
                  <td>{Math.round(item.share * 100) / 100}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default VestingSchedule;
