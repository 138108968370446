import { socialIconsMap } from "../../constants/socialIconsMap";

const SocialIcon = ({ title, url }: { title: string; url?: string }) => {
  const tag = url ? (
    <a target="_blank" href={url}>
      <img src={socialIconsMap.get(title)} alt={`${title}-svg`} />
    </a>
  ) : (
    <div>
      <img src={socialIconsMap.get(title)} alt={`${title}-svg`} />
    </div>
  );

  return <>{tag}</>;
};

export default SocialIcon;
