import CreatedBy from "./components/CreatedBy";
import GetInTouch from "./components/GetInTouch";
import MeetOurTeam from "./components/MeetOurTeam";

const Team = () => {
  return (
    <>
      <MeetOurTeam />
      <GetInTouch />
      <CreatedBy />
    </>
  );
};

export default Team;
