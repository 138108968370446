const Technology = () => {
  return (
    <section
      id="whitepaper-technology"
      className="pt-2.5 text-section-text mt-20"
    >
      <h3 className="container section-heading text-white uppercase text-left">
        03 The technology behind NX7
      </h3>
      <div className="lg:grid lg:grid-cols-1 lg:grid-rows-1 bg-dark-purple">
        <div className="col-start-1 col-end-1 row-start-1 row-end-1 lg:grid grid-cols-2 auto-rows-fr gap-10">
          <img
            className="col-start-2 h-full object-cover w-full md:max-h-none max-h-[450px]"
            src="assets/images/pages/whitepaper/nx7-technology.png"
            alt="Technology Image"
          />
        </div>
        <div className="col-start-1 col-end-1 row-start-1 row-end-1 container grid lg:grid-cols-2 auto-rows-fr gap-10">
          <div className="my-20">
            <h2 className="section-heading leading-tight text-left">
              The Technology behind NX7 Token
            </h2>
            <p className="text-justify">
              Using NFT
              <img
                className="nftx-x h-[var(--fs-small-text)]"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />
              7s allows for a crowd-funded method of starting a trading
              portfolio. The speed and high liquidity of NFT
              <img
                className="nftx-x h-[var(--fs-small-text)]"
                src="../assets/images/logo/nftx-x.png"
                alt="X"
              />
              7s make them an ideal choice for this project. Our deflationary
              token model ensures that the value of the token can appreciate,
              which may lead to an increase in the value of the rewards.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technology;
