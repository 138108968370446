import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { setupStore } from "./store/store";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { AxiosMiddleware } from "./networking/middleware";
import { DAppProvider } from "@usedapp/core";
import config from "./config";
import { NetworkContractsContextProvider } from "./context/providers/NetworkContracts";

const store = setupStore();

AxiosMiddleware.boot();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <DAppProvider config={config.DappConfig as any}>
      <NetworkContractsContextProvider>
        <App />
      </NetworkContractsContextProvider>
    </DAppProvider>
  </Provider>
);

reportWebVitals();
