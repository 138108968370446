import { useState } from "react";
import CopySvg from "./CopySvg";

const ImportNFTAddress = () => {
  const tokenValue = "0x0488b310C8E10502e6B9cc4F1F561B22e9dA365e";
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(tokenValue);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <section id="import-address">
      <h4 className="mb-2">
        IMPORT NFT
        <img
          className="nftx-x h-[var(--fs-small-text)]"
          src="../assets/images/logo/nftx-x.png"
          alt="X"
        />
        7 ADDRESS
      </h4>
      <div onClick={handleCopyClick}>
        {isCopied ? (
          <span>Link Copied!</span>
        ) : (
          <div className="flex gap-3 cursor-pointer">
            <span className="break-all transition-transform duration-500 hover:translate-x-0.5 hover:translate-y-0.5">
              {tokenValue}
            </span>
            <CopySvg />
          </div>
        )}
      </div>
    </section>
  );
};

export default ImportNFTAddress;
