import React from "react";

const Conclusion = () => {
  return (
    <section id="whitepaper-conclusion" className="container">
      <div className="numbered-section pt-2.5 text-section-text mt-20">
        <h3 className="section-heading text-white uppercase text-left">
          05 Conclusion
        </h3>
        <p className="text-justify">
          NX7 Token is a new NFT
          <img
            className="nftx-x h-[var(--fs-small-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 project that offers weekly rewards to NFT
          <img
            className="nftx-x h-[var(--fs-small-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 owners in exchange for staking their NFT
          <img
            className="nftx-x h-[var(--fs-small-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7s. The token is fixed at a minting price of 100 USDT and can be
          traded on Opensea, giving owners an opportunity to generate extra
          profits. The use of NFT
          <img
            className="nftx-x h-[var(--fs-small-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7s allows for a crowd-funded approach to starting a trading portfolio,
          while the deflationary token model ensures that the token's value can
          appreciate over time. In case NFT
          <img
            className="nftx-x h-[var(--fs-small-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 owners cannot sell their NFT
          <img
            className="nftx-x h-[var(--fs-small-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 at a higher price, they can rely on the creators to repurchase the
          NFT for its nominal value. With NX7 Token, NFT
          <img
            className="nftx-x h-[var(--fs-small-text)]"
            src="../assets/images/logo/nftx-x.png"
            alt="X"
          />
          7 owners can earn rewards while contributing to a larger investment
          strategy.
        </p>
      </div>
    </section>
  );
};

export default Conclusion;
