import SocialIcon from "../../../components/common/SocialIcon";
import defaultSocialLinks from "../../../constants/socials";

const GetInTouch = () => {
  return (
    <section
      id="team-touch"
      className="section-margin flex flex-col items-center text-center uppercase gap-6"
    >
      <h2 className="section-heading text-center">Get In Touch</h2>
      <h4 className="text-paragraph-text leading-normal">
        We're on Discord!
        <br />
        Come say hi and ask us questions.
      </h4>
      <a
        className="flex items-center justify-center gap-5 text-medium-text font-extrabold bg-dark-purple border-2 border-dark-purple py-5 px-7 rounded-[50px] hover:text-dark-purple hover:bg-light-purple hover:rounded-[20px_50px_20px_50px]"
        style={{
          transition: "background-color .5s, color .5s, border-radius .25s",
        }}
        target="_blank"
        href={defaultSocialLinks[0].url}
      >
        <div className="max-w-[60px]">
          <SocialIcon title="Discord" />
        </div>
        <span>Join us</span>
      </a>
    </section>
  );
};

export default GetInTouch;
