import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
// import { RequiresAuthentication } from "./ProtectedRoutes";
import Unauthorized from "../pages/ErrorHandeling/401";
import PageNotFound from "../pages/ErrorHandeling/404";
import About from "../pages/About/About";
import MyNft from "../pages/MyNft/MyNft";
import Team from "../pages/Team/Team";
import StakeNft from "../pages/StakeNft/StakeNft";
import Whitepaper from "../pages/Whitepaper/Whitepaper";
import Refund from "../pages/Buyback/Buyback";

function AppRouter() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<About />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/team" element={<Team />}></Route>
        <Route path="/mint" element={<MyNft />}></Route>
        <Route path="/stake" element={<StakeNft />}></Route>
        {/* <Route path="/buyback" element={<Refund />}></Route> */}
        <Route path="/whitepaper" element={<Whitepaper />}></Route>
        <Route path="401" element={<Unauthorized />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default AppRouter;
