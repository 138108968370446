export const teamMembers = [
  {
    id: 5,
    title: "The Cypher",
    image: "/assets/images/pages/team/the-cypher.png",
    description: "The one who gave birth to this websites",
  },
  {
    id: 4,
    title: "Ace",
    image: "/assets/images/pages/team/ace.png",
    description: "The one that keeps our balances in check",
  },
  {
    id: 3,
    title: "The Layout",
    image: "/assets/images/pages/team/the-layout.png",
    description:
      "The one who turned our abstract concepts into stunning, mesmerizing visual masterpieces",
  },
  {
    id: 2,
    title: "Integral",
    image: "/assets/images/pages/team/integral.png",
    description:
      "The one who generated, inovated and expanded the inventive concepts",
  },
  {
    id: 1,
    title: "Cobra",
    image: "/assets/images/pages/team/cobra.png",
    description:
      "The one who came up with all our brilliant, sometimes quaint, ideas",
  },
];

export default teamMembers;
