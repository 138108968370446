import { ChainId } from "@usedapp/core";

const ContractsConfig = {
    [ChainId.Polygon]: {
        NX7NFT: process.env.REACT_APP_NX7NFT_ADDRESS,
        NX7TOKEN: process.env.REACT_APP_NX7TOKEN_ADDRESS,
        NX7STAKING: process.env.REACT_APP_NX7STAKING_ADDRESS,
    },
};

export default ContractsConfig;
