const CopySvg = () => {
  return (
    <svg
      className="max-w-[24px]"
      xmlns="http://www.w3.org/2000/svg"
      width="27.815"
      height="26.47"
      viewBox="0 0 27.815 26.47"
    >
      <g id="Group_52" data-name="Group 52" transform="translate(1 1)">
        <g id="Group_50" data-name="Group 50" transform="translate(8.232)">
          <path
            id="Path_94"
            data-name="Path 94"
            d="M74.277,16.686H61.165A2.238,2.238,0,0,1,58.93,14.45V2.235A2.238,2.238,0,0,1,61.165,0H74.277a2.238,2.238,0,0,1,2.235,2.235V14.45A2.238,2.238,0,0,1,74.277,16.686ZM61.165,1.118a1.119,1.119,0,0,0-1.118,1.118V14.45a1.119,1.119,0,0,0,1.118,1.118H74.277a1.119,1.119,0,0,0,1.118-1.118V2.235a1.119,1.119,0,0,0-1.118-1.118Z"
            transform="translate(-58.93)"
            fill="#9e72db"
            stroke="#9e72db"
            strokeWidth="2"
          />
        </g>
        <g id="Group_51" data-name="Group 51" transform="translate(0 7.784)">
          <path
            id="Path_95"
            data-name="Path 95"
            d="M15.347,72.406H2.235A2.238,2.238,0,0,1,0,70.17V57.955A2.238,2.238,0,0,1,2.235,55.72H7.317v1.118H2.235a1.119,1.119,0,0,0-1.118,1.118V70.17a1.119,1.119,0,0,0,1.118,1.118H15.347a1.119,1.119,0,0,0,1.118-1.118V65.535h1.118V70.17A2.237,2.237,0,0,1,15.347,72.406Z"
            transform="translate(0 -55.72)"
            fill="#9e72db"
            stroke="#9e72db"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default CopySvg;
