const JoinBanner = () => {
  return (
    <section id="about-banner">
      <img
        className="md:block hidden"
        src="/assets/images/pages/about/banner.jpg"
        alt="Banner Image"
      />
      <img
        className="md:hidden"
        src="/assets/images/pages/about/banner-mobile.jpg"
        alt="Banner Image Mobile"
      />
    </section>
  );
};

export default JoinBanner;
