const TokenomicsTitle = () => {
  return (
    <section id="whitepaper-tokenomics" className="container">
      <div className="numbered-section pt-2.5 text-section-text mt-14">
        <h3 className="section-heading text-white uppercase text-left">
          04 Tokenomics
        </h3>
      </div>
    </section>
  );
};

export default TokenomicsTitle;
